<template>
  <div class="mod-companyspecialday">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.name"
            placeholder="公司名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-select
            v-model="searchForm.status"
            placeholder="状态"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in statusOptions"
              :key="index"
              :label="item.name"
              :value="item.status"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="日期范围" class="typeInput">
          <el-date-picker
            v-model="value"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            v-if="isAuth('tc:companyspecialday:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:companyspecialday:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      stripe
      border
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="specialStartDay"
        header-align="center"
        align="center"
        label="特殊日期开始"
      >
      </el-table-column>
      <el-table-column
        prop="specialEndDay"
        header-align="center"
        align="center"
        label="特殊日期结束"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0" type="danger" size="small"
            >加班</el-tag
          >
          <el-tag v-else-if="scope.row.status == 1" size="small">放假</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="备注"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:companyspecialday:info')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('tc:companyspecialday:update')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('tc:companyspecialday:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './companyspecialday-add-or-update';
import { listMixin, normal } from '@/mixins';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      searchForm: {
        name: '',
        status: '',
        startTime: '',
        endTime: '',
      },
      statusOptions: [
        {
          status: 0,
          name: '加班',
        },
        {
          status: 1,
          name: '放假',
        },
      ],
      value: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList(1);
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.value?.length) {
        this.searchForm.startTime = this.value[0];
        this.searchForm.endTime = this.value[1];
      } else {
        this.searchForm.startTime = null;
        this.searchForm.endTime = null;
      }
      this.$http({
        url: '/tc/companyspecialday/list',
        method: 'get',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          name: this.searchForm.name,
          status: this.searchForm.status,
          startTime: this.searchForm.startTime,
          endTime: this.searchForm.endTime,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },

    // 查看详情
    showDetails(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, true);
      });
    },

    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对选中数据进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/tc/companyspecialday/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.el-popper {
  width: auto;
  max-height: 500px;
  max-width: 550px;
}
</style>

<style lang="scss" scoped>
.DatepickerTime {
  width: 400px !important;
  .el-range-separator {
    width: 20px;
    font-size: 12px;
  }
}
</style>
